.tim-row{
    margin-bottom: 20px;
}



.tim-white-buttons {
    background-color: #777777;
}
.typography-line{
    padding-left: 25%;
    margin-bottom: 35px;
    position: relative;
    display: block;
    width: 100%;
}
.typography-line span{
    bottom: 10px;
    color: #c0c1c2;
    display: block;
    font-weight: 400;
    font-size: 13px;
    line-height: 13px;
    left: 0;
    position: absolute;
    width: 260px;
    text-transform: none;
}
.tim-row{
    padding-top: 60px;
}
.tim-row h3{
    margin-top: 0;
}
.switch{
    margin-right: 20px;
}
#navbar-full .navbar{
    border-radius: 0 !important;
    margin-bottom: 15px;
    z-index: 2;
}

#map{
    position:relative;
    width:100%;
    height: 100vh;
}

.map-container{
    width: 100%;
    height: 100vh;
    max-height: 100vh;
}

#menu-dropdown .navbar{
    border-radius: 3px;
}

#pagination-row .pagination-container{
    height: 100%;
    max-height: 100%;
    display: flex;
    align-items: center;
}

.all-icons .font-icon-detail {
    text-align: center;
    padding: 45px 0px 30px;
    border: 1px solid #e5e5e5;
    border-radius: 0.1875rem;
    margin: 15px 0;
    min-height: 168px;
}

.all-icons [class*="now-ui-icons"] {
    font-size: 32px;
}

.all-icons .font-icon-detail p {
    margin: 25px auto 0;
    width: 100%;
    text-align: center;
    display: block;
    color: #B8B8B8;
    padding:0 10px;
    font-size: 0.7142em;
}

#icons-row i.now-ui-icons{
    font-size: 30px;
}

.space{
    height: 130px;
    display: block;
}
.space-110{
    height: 110px;
    display: block;
}
.space-50{
    height: 50px;
    display: block;
}
.space-70{
    height: 70px;
    display: block;
}
.navigation-example .img-src{
    background-attachment: scroll;
}

.navigation-example{
    background-position: center center;
    background-size: cover;
    margin-top:0;
    min-height: 740px;
}
#notifications{
    background-color: #FFFFFF;
    display: block;
    width: 100%;
    position: relative;
}
.tim-note{
    text-transform: capitalize;
}

#buttons .btn,
#javascriptComponents .btn{
    margin: 0 0px 10px;
}
.space-100{
    height: 100px;
    display: block;
    width: 100%;
}

.be-social{
    padding-bottom: 20px;
/*     border-bottom: 1px solid #aaa; */
    margin: 0 auto 40px;
}
.txt-white{
    color: #FFFFFF;
}
.txt-gray{
    color: #ddd !important;
}


.parallax{
  width:100%;
  height:570px;

  display: block;
  background-attachment: fixed;
    background-repeat:no-repeat;
    background-size:cover;
    background-position: center center;

}

.logo-container .logo{
    overflow: hidden;
    border-radius: 50%;
    border: 1px solid #333333;
    width: 50px;
    float: left;
}

.logo-container .brand{
    font-size: 16px;
    color: #FFFFFF;
    line-height: 18px;
    float: left;
    margin-left: 10px;
    margin-top: 7px;
    width: 70px;
    height: 40px;
    text-align: left;
}
.logo-container .brand-material{
    font-size: 18px;
    margin-top: 15px;
    height: 25px;
    width: auto;
}
.logo-container .logo img{
    width: 100%;
}
.navbar-small .logo-container .brand{
    color: #333333;
}

.fixed-section{
    top: 90px;
    max-height: 80vh;
    overflow: scroll;
    position: sticky;
}

.fixed-section ul{
    padding: 0;
}

.fixed-section ul li{
    list-style: none;
}
.fixed-section li a{
    font-size: 14px;
    padding: 2px;
    display: block;
    color: #666666;
}
.fixed-section li a.active{
    color: #00bbff;
}
.fixed-section.float{
    position: fixed;
    top: 100px;
    width: 200px;
    margin-top: 0;
}


.parallax .parallax-image{
    width: 100%;
    overflow: hidden;
    position: absolute;
}
.parallax .parallax-image img{
    width: 100%;
}

@media (max-width: 768px){
    .parallax .parallax-image{
         width: 100%;
         height: 640px;
         overflow: hidden;
     }
    .parallax .parallax-image img{
       height: 100%;
       width: auto;
   }
}

/*.separator{
    content: "Separator";
    color: #FFFFFF;
    display: block;
    width: 100%;
    padding: 20px;
}
.separator-line{
    background-color: #EEE;
    height: 1px;
    width: 100%;
    display: block;
}
.separator.separator-gray{
    background-color: #EEEEEE;
}*/
.social-buttons-demo .btn{
    margin-right: 5px;
    margin-bottom: 7px;
}

.img-container{
    width: 100%;
    overflow: hidden;
}
.img-container img{
    width: 100%;
}

.lightbox img{
    width: 100%;
}
.lightbox .modal-content{
    overflow: hidden;
}
.lightbox .modal-body{
    padding: 0;
}
@media screen and (min-width: 991px){
    .lightbox .modal-dialog{
        width: 960px;
    }
}
@media (max-width: 991px){
    .fixed-section.affix{
        position: relative;
        margin-bottom: 100px;
    }
}
@media (max-width: 768px){
    .btn, .btn-morphing{
        margin-bottom: 10px;
    }
    .parallax .motto{
        top: 170px;
        margin-top: 0;
        font-size: 60px;
        width: 270px;
    }
}

/*       Loading dots  */

/*      transitions */
.presentation .front, .presentation .front:after, .presentation .front .btn, .logo-container .logo, .logo-container .brand{
     -webkit-transition: all .2s;
    -moz-transition: all .2s;
    -o-transition: all .2s;
    transition: all .2s;
}


#images h4{
    margin-bottom: 30px;
}
#javascriptComponents{
    padding-bottom: 0;
}
/*      layer animation          */

.layers-container{
    display: block;
    margin-top: 50px;
    position: relative;
}
.layers-container img {
  position: absolute;
  width: 100%;
  height: auto;
  top: 0;
  left: 0;
  text-align: center;
}

.animate {
  transition: 1.5s ease-in-out;
  -moz-transition: 1.5s ease-in-out;
  -webkit-transition: 1.5s ease-in-out;
}

.navbar-default.navbar-small .logo-container .brand{
    color: #333333;
}
.navbar-transparent.navbar-small .logo-container .brand{
    color: #FFFFFF;
}
.navbar-default.navbar-small .logo-container .brand{
    color: #333333;
}

.sharing-area{
    margin-top: 80px;
}
.sharing-area .btn{
    margin: 15px 4px 0;
}

.section-thin,
.section-notifications{
    padding: 0;
}
.section-navbars{
    padding-top: 0;
}
#navbar .navbar{
    margin-bottom: 20px;
}

#navbar .navbar-toggler,
#menu-dropdown .navbar-toggler{
    pointer-events: none;
}
.section-tabs{
    background: #EEEEEE;
}
.section-pagination{
    padding-bottom: 0;
}
.section-download{
    padding-top: 130px;
}
.section-download .description{
    margin-bottom: 60px;
}
.section-download h4{
    margin-bottom: 25px;
}
.section-examples a{
    text-decoration: none;
}
.section-examples a + a{
    margin-top: 30px;
}
.section-examples h5{
    margin-top: 30px;
}
.components-page .wrapper > .header,
.tutorial-page .wrapper > .header{
    height: 500px;
    padding-top: 128px;
    background-size: cover;
    background-position: center center;
}
.components-page .title,
.tutorial-page .title{
    color: #FFFFFF;
}

.brand .h1-seo{
    font-size: 2.8em;
    text-transform: uppercase;
    font-weight: 300;
}
.brand .n-logo{
    max-width: 100px;
    margin-bottom: 40px;
}
.invision-logo{
    max-width: 70px;
    top: -2px;
    position: relative;
}
.creative-tim-logo{
    max-width: 140px;
    top: -2px;
    position: relative;
}
.section-javascript .title{
    margin-bottom: 0;
}

.navbar .switch-background{
    display: block;
}
.navbar-transparent .switch-background{
    display: none;
}

.section-signup .col .btn{
    margin-top: 30px;
}

#buttons-row .btn{
    margin-bottom: 10px;
}

.section-navbars .navbar-collapse{
    display: none;
}

.section-basic{
    padding-top: 0;
}
.section-images{
    padding-bottom: 0;
}

/* own */
.info .icon>svg {
    font-size: 2.3em;
}
.fade-enter {
    opacity: 0;
}

.fade-enter-active {
    opacity: 1;
    transition: opacity 100ms cubic-bezier(0.55, 0.085, 0.68, 0.53);
}

.fade-exit {
    opacity: 1;
}

.fade-exit-active {
    opacity: 0;
    transition: opacity 100ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.vertical-center {
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}


.loader-center {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 2;
    transform: translate(-50%, -50%);
}

.loader .spinner {
    width: 120px;
    height: 120px;
    z-index: 2;
    /* margin: -76px 0 0 -76px; */
    border: 16px solid #232323;
    border-radius: 50%;
    border-right: 16px solid #444;
    -webkit-animation: spin 2s cubic-bezier(0.68, -0.55, 0.27, 1.55) infinite;
    animation: spin 2s cubic-bezier(0.68, -0.55, 0.27, 1.55) infinite;
}

.h {
    visibility: hidden;
    opacity: 0;
    transition: visibility 1s 1s, opacity 1.25s linear;
}

.wrapper.animate {
    opacity: 0;
    transition: opacity 0.5s linear;
}


.show.animate {
    opacity: 1;
    transition: opacity 0.5s linear;
}


@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.blur-backplane {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background-color: rgba(197, 197, 197, 0.15);
    backdrop-filter: blur(10px);

}

.bg-blur {
    background-color: rgba(255, 255, 255, .15);
    backdrop-filter: blur(5px);
}

.text-invert {
    mix-blend-mode: difference;
    color: white;
}

.login-page .card-login {
    border-radius: 20px;
}

.login-page input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #ddd;
    opacity: 1; /* Firefox */
}

.border-primary {
    border-width: 2.5px !important;
    border-color: #f96332 !important;
}

.card {
    border-radius: 20px;
}

.modal-content {
    border-radius: 20px;
}

.btn {
    border-radius: 12.5px !important;
}

.form-control {
    border-radius: 12.5px;
}

.fixed-plugin .text-blue {
    color: #2ca8ff;
}

.fixed-plugin .text-green {
    color: #18ce0f;
}

.fixed-plugin .text-orange {
    color: #f96332;
}

.fixed-plugin .text-yellow {
    color: #ffb236;
}

.fixed-plugin .text-red {
    color: #ff3636;
}

.fixed-plugin.border {
    border-color: rgba(129, 129, 129, 0.25) !important;
}

.fixed-plugin .badge.active, .fixed-plugin .badge:hover {
    border-color: #333333;
}

.bootstrap-switch .bootstrap-switch-label {
    width: 20px !important;
    height: 20px !important;
}

.card-stats [class*="col-"] .statistics::after {
    background: #0000;
}

/* DARK MODE */

.popover .popover-header {
    color: #ffffff;
}

.popover {
    background-color: #2c2c2c;
    border: #fff solid 1px;
}

.popover .popover-body {
    color: #ffffff;
}

.popover .popover-body hr {
    border-top: 1px solid #ffffff;
}

.popover .arrow {
    display: none;
}

.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    border-bottom: 0px;
}


.updateProfile .form-control[disabled], .updateProfile .form-control[readonly], .updateProfile fieldset[disabled] .form-control {
    background-color: rgba(227, 227, 227, 0.18);
    color: #888;
    cursor: not-allowed;
}

.updateProfile .form-control {
    color: #fff;
}

.updateProfile .form-check .form-check-sign::after {
    color: #fff;

}

.updateProfile .form-control:focus {
    background-color: #0000;
    color: #fff;
}

.updateProfile .card label {
    color: #fff;
}

.border-bottom-right-rounded {
    border-radius: 0px !important;
    border-bottom-left-radius: 8px !important;

}

/* Cookie Consent */

.react-cookie-law-accept-selection-btn {

    font-weight: 400;
    font-size: 0.8571em;
    line-height: 1.35em;
    margin: 10px 1px;
    border-radius: 12.5px;
    cursor: pointer;
    border: 1px solid;
    padding: 11px 22px;
    margin-right: 1em;

    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;

}

.react-cookie-law-accept-all-btn {
    border-width: 2px;
    font-weight: 400;
    font-size: 0.8571em;
    line-height: 1.35em;
    border: none;
    margin: 10px 1px;
    border-radius: 12.5px;
    padding: 11px 22px;
    cursor: pointer;
    background-color: #f96332;

    color: #fff;
}

.react-cookie-law-option-wrapper label {
    height: auto;
    width: auto;
    min-height: 14px;
    font-size: 12pt;
    color: #ffffff;
    display: inline-block;
    padding: 1px 0px 0px 20px;
    position: relative;
    top: 0px;
    left: 0px;
    z-index: 1;
    cursor: default;
    vertical-align: top;
}

.react-cookie-law-msg {
    color: #ffffff;
    font-size: 1.2em !important;
    font-weight: bold !important;
    text-align: center;
    margin-bottom: 5px !important;

    min-height: 32px;
    line-height: 130%;
    padding: 10px 0px;

}

.react-cookie-law-dialog {
    position: fixed;
    bottom: 0px;
    left: 0px;
    right: 0px;
    z-index: 100000;
    background-color: rgba(44, 44, 44, 0.5);
    backdrop-filter: blur(15px);
    padding: 10px;
}

.react-cookie-law-policy {
    display: none;

}

.react-cookie-law-select-pane {
    text-align: center !important;
}

.react-cookie-law-option-wrapper {
    display: inline-block;
    /* position: relative; */
    margin-right: 7px;
    margin-left: 7px;
    /* float: left; */
}

.react-cookie-law-button-wrapper {
    text-align: center;
}

.upload_btn {
    position: absolute;
    width: 100%;
    height: calc(1.5em + 1.25rem + 5px);
    z-index: 1;
    opacity: 0;
}

.rbc-today {
    background-color: rgba(162, 162, 162, 0.15);
}


select.bg-transparent {
    border: 1px solid #fff !important;
    background-color: rgba(255,255,255,.2) !important;
}
select.bg-transparent option {
    background-color: transparent;
}

.sweet-alert > div > div {
    background-color: transparent !important;
}

.sweet-alert {
    background-color: rgba(255, 255, 255, .15);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    border-radius: 12.5px !important;
}
.nav-dropdown {
    background-color: transparent !important;
    padding: 0.5rem 0.7rem !important;
}

.alert {
    border-radius: 12.5px !important;
}

.nav-dropdown:hover {
    background-color: rgba(255, 255, 255, 0.2) !important;
    border-radius: 0.1875rem !important;
    color: #ffffff !important;
    box-shadow: unset !important;
}

.modal .close {
    margin-right: -25px;
    margin-top: -15px;
    border-radius: 0px !important;
    border-bottom-left-radius: 20px !important;
    border-top-right-radius: 20px !important;
    background-color: rgba(210, 210, 210, 0.26) !important;
    color: #343434 !important;

}
