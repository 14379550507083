/*!

=========================================================
* React Wizard - v0.0.5
=========================================================

* Copyright 2021 Creative Tim (http://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/react-wizard/blob/master/LICENSE.md)

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

.wizard-container {
  .card-wizard {
    .card-header {
      font-size: 14px;
      font-family: "Montserrat", "Helvetica Neue", Arial, sans-serif;
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
    }

    .wizard-navigation {
      .moving-tab {
        font-size: 14px;
        font-family: "Montserrat", "Helvetica Neue", Arial, sans-serif;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
      }

      .nav .nav-item .nav-link {
        cursor: pointer;
      }
    }

    .card-footer {
      font-size: 14px;
      font-family: "Montserrat", "Helvetica Neue", Arial, sans-serif;
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
    }

    .wizard-navigation {
      .moving-tab {
        font-size: 12px;

        i {
          display: inline-block;
          font-size: 19px;
          line-height: initial;
          margin-right: 6px;
          padding: 0;
          vertical-align: bottom;
        }
      }

      .nav.nav-pills .nav-item a {
        margin-right: 0;
      }
    }

    min-height: 410px;
    box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.2);
    opacity: 0;
    -webkit-transition: all 300ms linear;
    -moz-transition: all 300ms linear;
    -o-transition: all 300ms linear;
    -ms-transition: all 300ms linear;
    transition: all 300ms linear;

    &.active {
      opacity: 1;
    }

    .nav-pills .nav-item .nav-link {
      padding-top: 8px;
      padding-bottom: 8px;
      background-color: transparent;
      box-shadow: none;

      &.active,
      &:hover,
      &:focus {
        background-color: transparent;
        box-shadow: none;
      }

      &.active {
        &:focus,
        &:hover {
          background-color: transparent;
          box-shadow: none;
        }
      }
    }

    .picture-container {
      position: relative;
      cursor: pointer;
      text-align: center;
    }

    .wizard-navigation {
      position: relative;
    }

    .moving-tab {
      position: absolute;
      text-align: center;
      padding: 12px;
      font-size: 12px;
      text-transform: uppercase;
      -webkit-font-smoothing: subpixel-antialiased;
      top: -4px;
      left: 0;
      border-radius: 30px;
      background-color: #fff;
      box-shadow: 0 1px 15px 1px rgba(39, 39, 39, 0.1);
      color: #f96332;
      cursor: pointer;
      font-weight: 500;
    }
  }

  [data-background-color="primary"] {
    background-color: #f96332;
  }

  [data-background-color="green"] {
    background-color: #18ce0f;
  }

  [data-background-color="blue"] {
    background-color: #2ca8ff;
  }

  [data-background-color="orange"] {
    background-color: #ffb236;
  }

  [data-background-color="red"] {
    background-color: #ff3636;
  }

  [data-background-color="primary"] .moving-tab {
    color: #f96332;
  }

  [data-background-color="green"] .moving-tab {
    color: #18ce0f;
  }

  [data-background-color="blue"] .moving-tab {
    color: #2ca8ff;
  }

  [data-background-color="orange"] .moving-tab {
    color: #ffb236;
  }

  [data-background-color="red"] .moving-tab {
    color: #ff3636;
  }

  .card-wizard {
    .tab-content {
      min-height: 355px;
      padding: 20px 0;
    }

    .wizard-footer {
      padding: 0 15px;
    }

    .disabled {
      display: none;
    }

    .wizard-header {
      text-align: center;
      padding: 25px 0 35px;

      h5 {
        margin: 5px 0 0;
      }
    }

    .nav-pills > li {
      text-align: center;
    }

    .btn {
      text-transform: uppercase;
    }

    .info-text {
      text-align: center;
      font-weight: 300;
      margin: 10px 0 30px;
    }

    .card-title {
      font-size: 2em;
      line-height: 1.4em;
      font-weight: 400;
      text-transform: none;
    }
  }
}

[data-background-color]:not([data-background-color="gray"]) {
  .description,
  .social-description p {
    color: hsla(0, 0%, 100%, 0.8);
  }
}

.wizard-container {
  .card-wizard {
    .card-title + .description {
      font-size: 17px;
      margin-bottom: 32px;
    }

    .wizard-title {
      margin: 0;
    }

    .nav-pills {
      background-color: inherit;

      > li {
        + li {
          margin-left: 0;
        }

        > a {
          border: 0 !important;
          border-radius: 0;
          line-height: 18px;
          text-transform: uppercase;
          font-size: 12px;
          font-weight: 500;
          min-width: 100px;
          text-align: center;
        }

        &.active > a {
          background-color: inherit;
          box-shadow: none;

          &:hover,
          &:focus {
            background-color: inherit;
            box-shadow: none;
          }
        }

        > a {
          &:hover,
          &:focus {
            background-color: inherit;
            box-shadow: none;
          }
        }

        i {
          display: inline-block;
          font-size: 19px;
          line-height: initial;
          margin-right: 6px;
          padding: 0;
          vertical-align: bottom;
        }
      }
    }
  }

  .nav-pills {
    &.flex-column li > a {
      margin-bottom: 15px;
    }

    &:not(.nav-pills-icons):not(.nav-pills-just-icons) .nav-item .nav-link {
      border-radius: 30px;
    }

    &.nav-pills-just-icons .nav-item .nav-link {
      border-radius: 50%;
      height: 80px;
      width: 80px;
      padding: 0;
      max-width: 80px;
      min-width: auto;
    }

    .nav-item {
      &:not(:last-child) .nav-link {
        margin-right: 19px;
      }

      .nav-link {
        padding: 0 15.5px;
        text-align: center;
        padding: 11px 23px;
        min-width: 100px;
        font-weight: 400;
        color: #444;
        background-color: rgba(222, 222, 222, 0.3);

        &:hover {
          background-color: rgba(222, 222, 222, 0.3);
        }

        &.active {
          background-color: #9a9a9a;
          color: #ffffff;
          box-shadow: 0px 5px 35px 0px rgba(0, 0, 0, 0.3);

          &:focus,
          &:hover {
            background-color: #9a9a9a;
            color: #ffffff;
            box-shadow: 0px 5px 35px 0px rgba(0, 0, 0, 0.3);
          }
        }

        &.disabled,
        &:disabled,
        &[disabled] {
          opacity: 0.5;
        }
      }
    }

    &.nav-pills-neutral .nav-item .nav-link {
      background-color: rgba(255, 255, 255, 0.2);
      color: #ffffff;

      &.active {
        background-color: #ffffff;
        color: #f96332;

        &:focus,
        &:hover {
          background-color: #ffffff;
          color: #f96332;
        }
      }
    }

    &.nav-pills-primary .nav-item .nav-link.active {
      background-color: #f96332;

      &:focus,
      &:hover {
        background-color: #f96332;
      }
    }

    &.nav-pills-info .nav-item .nav-link.active {
      background-color: #2ca8ff;

      &:focus,
      &:hover {
        background-color: #2ca8ff;
      }
    }

    &.nav-pills-success .nav-item .nav-link.active {
      background-color: #18ce0f;

      &:focus,
      &:hover {
        background-color: #18ce0f;
      }
    }

    &.nav-pills-warning .nav-item .nav-link.active {
      background-color: #ffb236;

      &:focus,
      &:hover {
        background-color: #ffb236;
      }
    }

    &.nav-pills-danger .nav-item .nav-link.active {
      background-color: #ff3636;

      &:focus,
      &:hover {
        background-color: #ff3636;
      }
    }
  }

  .tab-space {
    padding: 20px 0 50px 0px;
  }

  .tab-content.tab-subcategories {
    margin-top: 20px;
    background-color: #ffffff;
    padding-left: 15px;
    padding-right: 15px;
    box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.2);
  }

  .card-wizard {
    .btn:focus {
      outline: none;
      -webkit-box-shadow: none;
      box-shadow: none;
    }

    .card-footer {
      .btn {
        border-width: 2px;
        font-weight: 400;
        font-size: 0.8571em;
        line-height: 1.35em;
        border: none;
        border-radius: 0.1875rem;
        padding: 11px 22px;
        cursor: pointer;
        background-color: #888;
        color: #ffffff;

        &:hover,
        &:focus,
        &:active,
        &.active {
          background-color: #979797;
          color: #ffffff;
          box-shadow: none;
        }

        &:active {
          &:focus,
          &:hover {
            background-color: #979797;
            color: #ffffff;
            box-shadow: none;
          }
        }

        &.active {
          &:focus,
          &:hover {
            background-color: #979797;
            color: #ffffff;
            box-shadow: none;
          }
        }

        &:not([data-action]):hover {
          box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.17);
        }

        &.disabled {
          background-color: #888;
          border-color: #888;

          &:hover,
          &:focus,
          &.focus,
          &:active,
          &.active {
            background-color: #888;
            border-color: #888;
          }
        }

        &:disabled {
          background-color: #888;
          border-color: #888;

          &:hover,
          &:focus,
          &.focus,
          &:active,
          &.active {
            background-color: #888;
            border-color: #888;
          }
        }

        &[disabled] {
          background-color: #888;
          border-color: #888;

          &:hover,
          &:focus,
          &.focus,
          &:active,
          &.active {
            background-color: #888;
            border-color: #888;
          }
        }

        &.btn-simple {
          color: #888;
          border-color: #888;

          &:hover,
          &:focus,
          &:active {
            background-color: transparent;
            color: #979797;
            border-color: #979797;
            box-shadow: none;
          }
        }

        &.btn-link {
          color: #888;

          &:hover,
          &:focus,
          &:active {
            background-color: transparent;
            color: #979797;
            text-decoration: none;
            box-shadow: none;
          }
        }

        &:hover,
        &:focus {
          opacity: 1;
          filter: alpha(opacity=100);
          outline: 0 !important;
        }

        &:active,
        &.active {
          -webkit-box-shadow: none;
          box-shadow: none;
          outline: 0 !important;
        }
      }

      .btn-primary {
        background-color: #f96332;
        color: #ffffff;

        &:hover,
        &:focus,
        &:active,
        &.active {
          background-color: #fa7a50;
          color: #ffffff;
          box-shadow: none;
        }

        &:active {
          &:focus,
          &:hover {
            background-color: #fa7a50;
            color: #ffffff;
            box-shadow: none;
          }
        }

        &.active {
          &:focus,
          &:hover {
            background-color: #fa7a50;
            color: #ffffff;
            box-shadow: none;
          }
        }

        &:not([data-action]):hover {
          box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.17);
        }

        &.disabled {
          background-color: #f96332;
          border-color: #f96332;

          &:hover,
          &:focus,
          &.focus,
          &:active,
          &.active {
            background-color: #f96332;
            border-color: #f96332;
          }
        }

        &:disabled {
          background-color: #f96332;
          border-color: #f96332;

          &:hover,
          &:focus,
          &.focus,
          &:active,
          &.active {
            background-color: #f96332;
            border-color: #f96332;
          }
        }

        &[disabled] {
          background-color: #f96332;
          border-color: #f96332;

          &:hover,
          &:focus,
          &.focus,
          &:active,
          &.active {
            background-color: #f96332;
            border-color: #f96332;
          }
        }

        &.btn-simple {
          color: #f96332;
          border-color: #f96332;

          &:hover,
          &:focus,
          &:active {
            background-color: transparent;
            color: #fa7a50;
            border-color: #fa7a50;
            box-shadow: none;
          }
        }

        &.btn-link {
          color: #f96332;

          &:hover,
          &:focus,
          &:active {
            background-color: transparent;
            color: #fa7a50;
            text-decoration: none;
            box-shadow: none;
          }
        }
      }

      .btn-success {
        background-color: #18ce0f;
        color: #ffffff;

        &:hover,
        &:focus,
        &:active,
        &.active {
          background-color: #1beb11;
          color: #ffffff;
          box-shadow: none;
        }

        &:active {
          &:focus,
          &:hover {
            background-color: #1beb11;
            color: #ffffff;
            box-shadow: none;
          }
        }

        &.active {
          &:focus,
          &:hover {
            background-color: #1beb11;
            color: #ffffff;
            box-shadow: none;
          }
        }

        &:not([data-action]):hover {
          box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.17);
        }

        &.disabled {
          background-color: #18ce0f;
          border-color: #18ce0f;

          &:hover,
          &:focus,
          &.focus,
          &:active,
          &.active {
            background-color: #18ce0f;
            border-color: #18ce0f;
          }
        }

        &:disabled {
          background-color: #18ce0f;
          border-color: #18ce0f;

          &:hover,
          &:focus,
          &.focus,
          &:active,
          &.active {
            background-color: #18ce0f;
            border-color: #18ce0f;
          }
        }

        &[disabled] {
          background-color: #18ce0f;
          border-color: #18ce0f;

          &:hover,
          &:focus,
          &.focus,
          &:active,
          &.active {
            background-color: #18ce0f;
            border-color: #18ce0f;
          }
        }

        &.btn-simple {
          color: #18ce0f;
          border-color: #18ce0f;

          &:hover,
          &:focus,
          &:active {
            background-color: transparent;
            color: #1beb11;
            border-color: #1beb11;
            box-shadow: none;
          }
        }

        &.btn-link {
          color: #18ce0f;

          &:hover,
          &:focus,
          &:active {
            background-color: transparent;
            color: #1beb11;
            text-decoration: none;
            box-shadow: none;
          }
        }
      }

      .btn-info {
        background-color: #2ca8ff;
        color: #ffffff;

        &:hover,
        &:focus,
        &:active,
        &.active {
          background-color: #4bb5ff;
          color: #ffffff;
          box-shadow: none;
        }

        &:active {
          &:focus,
          &:hover {
            background-color: #4bb5ff;
            color: #ffffff;
            box-shadow: none;
          }
        }

        &.active {
          &:focus,
          &:hover {
            background-color: #4bb5ff;
            color: #ffffff;
            box-shadow: none;
          }
        }

        &:not([data-action]):hover {
          box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.17);
        }

        &.disabled {
          background-color: #2ca8ff;
          border-color: #2ca8ff;

          &:hover,
          &:focus,
          &.focus,
          &:active,
          &.active {
            background-color: #2ca8ff;
            border-color: #2ca8ff;
          }
        }

        &:disabled {
          background-color: #2ca8ff;
          border-color: #2ca8ff;

          &:hover,
          &:focus,
          &.focus,
          &:active,
          &.active {
            background-color: #2ca8ff;
            border-color: #2ca8ff;
          }
        }

        &[disabled] {
          background-color: #2ca8ff;
          border-color: #2ca8ff;

          &:hover,
          &:focus,
          &.focus,
          &:active,
          &.active {
            background-color: #2ca8ff;
            border-color: #2ca8ff;
          }
        }

        &.btn-simple {
          color: #2ca8ff;
          border-color: #2ca8ff;

          &:hover,
          &:focus,
          &:active {
            background-color: transparent;
            color: #4bb5ff;
            border-color: #4bb5ff;
            box-shadow: none;
          }
        }

        &.btn-link {
          color: #2ca8ff;

          &:hover,
          &:focus,
          &:active {
            background-color: transparent;
            color: #4bb5ff;
            text-decoration: none;
            box-shadow: none;
          }
        }
      }

      .btn-warning {
        background-color: #ffb236;
        color: #ffffff;

        &:hover,
        &:focus,
        &:active,
        &.active {
          background-color: #ffbe55;
          color: #ffffff;
          box-shadow: none;
        }

        &:active {
          &:focus,
          &:hover {
            background-color: #ffbe55;
            color: #ffffff;
            box-shadow: none;
          }
        }

        &.active {
          &:focus,
          &:hover {
            background-color: #ffbe55;
            color: #ffffff;
            box-shadow: none;
          }
        }

        &:not([data-action]):hover {
          box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.17);
        }

        &.disabled {
          background-color: #ffb236;
          border-color: #ffb236;

          &:hover,
          &:focus,
          &.focus,
          &:active,
          &.active {
            background-color: #ffb236;
            border-color: #ffb236;
          }
        }

        &:disabled {
          background-color: #ffb236;
          border-color: #ffb236;

          &:hover,
          &:focus,
          &.focus,
          &:active,
          &.active {
            background-color: #ffb236;
            border-color: #ffb236;
          }
        }

        &[disabled] {
          background-color: #ffb236;
          border-color: #ffb236;

          &:hover,
          &:focus,
          &.focus,
          &:active,
          &.active {
            background-color: #ffb236;
            border-color: #ffb236;
          }
        }

        &.btn-simple {
          color: #ffb236;
          border-color: #ffb236;

          &:hover,
          &:focus,
          &:active {
            background-color: transparent;
            color: #ffbe55;
            border-color: #ffbe55;
            box-shadow: none;
          }
        }

        &.btn-link {
          color: #ffb236;

          &:hover,
          &:focus,
          &:active {
            background-color: transparent;
            color: #ffbe55;
            text-decoration: none;
            box-shadow: none;
          }
        }
      }

      .btn-danger {
        background-color: #ff3636;
        color: #ffffff;

        &:hover,
        &:focus,
        &:active,
        &.active {
          background-color: #ff5555;
          color: #ffffff;
          box-shadow: none;
        }

        &:active {
          &:focus,
          &:hover {
            background-color: #ff5555;
            color: #ffffff;
            box-shadow: none;
          }
        }

        &.active {
          &:focus,
          &:hover {
            background-color: #ff5555;
            color: #ffffff;
            box-shadow: none;
          }
        }

        &:not([data-action]):hover {
          box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.17);
        }

        &.disabled {
          background-color: #ff3636;
          border-color: #ff3636;

          &:hover,
          &:focus,
          &.focus,
          &:active,
          &.active {
            background-color: #ff3636;
            border-color: #ff3636;
          }
        }

        &:disabled {
          background-color: #ff3636;
          border-color: #ff3636;

          &:hover,
          &:focus,
          &.focus,
          &:active,
          &.active {
            background-color: #ff3636;
            border-color: #ff3636;
          }
        }

        &[disabled] {
          background-color: #ff3636;
          border-color: #ff3636;

          &:hover,
          &:focus,
          &.focus,
          &:active,
          &.active {
            background-color: #ff3636;
            border-color: #ff3636;
          }
        }

        &.btn-simple {
          color: #ff3636;
          border-color: #ff3636;

          &:hover,
          &:focus,
          &:active {
            background-color: transparent;
            color: #ff5555;
            border-color: #ff5555;
            box-shadow: none;
          }
        }

        &.btn-link {
          color: #ff3636;

          &:hover,
          &:focus,
          &:active {
            background-color: transparent;
            color: #ff5555;
            text-decoration: none;
            box-shadow: none;
          }
        }
      }

      .btn-neutral {
        background-color: #ffffff;
        color: #f96332;

        &:hover,
        &:focus,
        &:active,
        &.active {
          background-color: #ffffff;
          color: #ffffff;
          box-shadow: none;
        }

        &:active {
          &:focus,
          &:hover {
            background-color: #ffffff;
            color: #ffffff;
            box-shadow: none;
          }
        }

        &.active {
          &:focus,
          &:hover {
            background-color: #ffffff;
            color: #ffffff;
            box-shadow: none;
          }
        }

        &:not([data-action]):hover {
          box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.17);
        }

        &.disabled {
          background-color: #ffffff;
          border-color: #ffffff;

          &:hover,
          &:focus,
          &.focus,
          &:active,
          &.active {
            background-color: #ffffff;
            border-color: #ffffff;
          }
        }

        &:disabled {
          background-color: #ffffff;
          border-color: #ffffff;

          &:hover,
          &:focus,
          &.focus,
          &:active,
          &.active {
            background-color: #ffffff;
            border-color: #ffffff;
          }
        }

        &[disabled] {
          background-color: #ffffff;
          border-color: #ffffff;

          &:hover,
          &:focus,
          &.focus,
          &:active,
          &.active {
            background-color: #ffffff;
            border-color: #ffffff;
          }
        }

        &.btn-danger {
          color: #ff3636;

          &:hover,
          &:focus,
          &:active {
            color: #ff5555;
          }
        }

        &.btn-info {
          color: #2ca8ff;

          &:hover,
          &:focus,
          &:active {
            color: #4bb5ff;
          }
        }

        &.btn-warning {
          color: #ffb236;

          &:hover,
          &:focus,
          &:active {
            color: #ffbe55;
          }
        }

        &.btn-success {
          color: #18ce0f;

          &:hover,
          &:focus,
          &:active {
            color: #1beb11;
          }
        }

        &.btn-default {
          color: #888;

          &:hover,
          &:focus,
          &:active {
            color: #979797;
          }
        }

        &.active {
          background-color: #ffffff;
          color: #fa7a50;
          box-shadow: none;
        }

        &:active {
          background-color: #ffffff;
          color: #fa7a50;
          box-shadow: none;

          &:focus,
          &:hover {
            background-color: #ffffff;
            color: #fa7a50;
            box-shadow: none;
          }
        }

        &.active {
          &:focus,
          &:hover {
            background-color: #ffffff;
            color: #fa7a50;
            box-shadow: none;
          }
        }

        &:hover,
        &:focus {
          color: #fa7a50;
        }

        &:hover:not(.nav-link),
        &:focus:not(.nav-link) {
          box-shadow: none;
        }

        &.btn-simple {
          color: #ffffff;
          border-color: #ffffff;

          &:hover,
          &:focus,
          &:active {
            background-color: transparent;
            color: #ffffff;
            border-color: #ffffff;
            box-shadow: none;
          }
        }

        &.btn-link {
          color: #ffffff;

          &:hover,
          &:focus,
          &:active {
            background-color: transparent;
            color: #ffffff;
            text-decoration: none;
            box-shadow: none;
          }
        }
      }

      .btn {
        &:disabled,
        &[disabled],
        &.disabled {
          opacity: 0.5;
          filter: alpha(opacity=50);
        }
      }

      .btn-round {
        border-width: 1px;
        border-radius: 30px;
        padding: 11px 23px;

        &.btn-simple {
          padding: 10px 22px;
        }
      }

      .btn-simple {
        border: 1px solid;
        border-color: #888;
        padding: 10px 22px;
        background-color: transparent;

        &.disabled {
          background-color: transparent;

          &:hover,
          &:focus,
          &.focus,
          &:active,
          &.active {
            background-color: transparent;
          }
        }

        &:disabled {
          background-color: transparent;

          &:hover,
          &:focus,
          &.focus,
          &:active,
          &.active {
            background-color: transparent;
          }
        }

        &[disabled] {
          background-color: transparent;

          &:hover,
          &:focus,
          &.focus,
          &:active,
          &.active {
            background-color: transparent;
          }
        }
      }

      .btn-link {
        &.disabled {
          background-color: transparent;

          &:hover,
          &:focus,
          &.focus,
          &:active,
          &.active {
            background-color: transparent;
          }
        }

        &:disabled {
          background-color: transparent;

          &:hover,
          &:focus,
          &.focus,
          &:active,
          &.active {
            background-color: transparent;
          }
        }

        &[disabled] {
          background-color: transparent;

          &:hover,
          &:focus,
          &.focus,
          &:active,
          &.active {
            background-color: transparent;
          }
        }
      }

      .btn-lg {
        font-size: 1em;
        border-radius: 0.25rem;
        padding: 15px 48px;

        &.btn-simple {
          padding: 14px 47px;
        }
      }

      .btn-sm {
        font-size: 14px;
        border-radius: 0.1875rem;
        padding: 5px 15px;

        &.btn-simple {
          padding: 4px 14px;
        }
      }

      .btn-link {
        border: 0;
        padding: 0.5rem 0.7rem;
        background-color: transparent;
      }

      .btn-wd {
        min-width: 140px;
      }
    }
  }
}
