@import "now-ui-dashboard/variables";

.bg-circle {
  border-radius: round(100%);
}
.border-radius-20 {
  border-radius: 20px;
}
.border-radius-50 {
  border-radius: 50px;
}

.ReactTable .rt-thead .rt-th.-cursor-pointer > div:first-of-type:after {
  content: none !important;
}


@-webkit-keyframes AnimationName {
  0%{background-position:0% 50%}
  50%{background-position:100% 50%}
  100%{background-position:0% 50%}
}
@-moz-keyframes AnimationName {
  0%{background-position:0% 50%}
  50%{background-position:100% 50%}
  100%{background-position:0% 50%}
}
@-o-keyframes AnimationName {
  0%{background-position:0% 50%}
  50%{background-position:100% 50%}
  100%{background-position:0% 50%}
}
@keyframes AnimationName {
  0%{background-position:0% 50%}
  50%{background-position:100% 50%}
  100%{background-position:0% 50%}
}


@mixin panel-header-color($color) {
    & {
      background: $color;
      background-size: 400% 400%;

      -webkit-animation: AnimationName 31s ease infinite;
      -moz-animation: AnimationName 31s ease infinite;
      -o-animation: AnimationName 31s ease infinite;
      animation: AnimationName 31s ease infinite;
    }
}

$info-gradient : linear-gradient(45deg, #46b4ff 15%, rgba(6, 136, 224, 0.6));
$success-gradient :linear-gradient(45deg, #57de51 15%, rgba(14, 225, 4, 0.6));
$warning-gradient : linear-gradient(45deg, #e1b360 15%, rgba(227, 150, 19, 0.6));
$danger-gradient : linear-gradient(45deg, #f85656 15%, rgba(253, 21, 21, 0.6));
$orange-gradient : linear-gradient(45deg, #f8835d 15%, rgba(248, 79, 24, 0.6));



.panel-header {
  &[data-color="blue"] {
    @include panel-header-color($info-gradient);
  }

  &[data-color="green"] {
    @include panel-header-color($success-gradient);
  }

  &[data-color="orange"] {
    @include panel-header-color($orange-gradient);
  }

  &[data-color="red"] {
    @include panel-header-color($danger-gradient);
  }

  &[data-color="yellow"] {
    @include panel-header-color($warning-gradient);
  }
}

[data-color="blue"] {
  .card-collapse .card .card-header a[data-toggle="collapse"] i {
    color: $info-color;
  }
}
[data-color="green"] {
  .card-collapse .card .card-header a[data-toggle="collapse"] i {
    color: $success-color;
  }
}
[data-color="orange"] {
  .card-collapse .card .card-header a[data-toggle="collapse"] i {
    color: $orange-color;
  }
}
[data-color="red"] {
  .card-collapse .card .card-header a[data-toggle="collapse"] i {
    color: $danger-color;
  }
}
[data-color="yellow"] {
  .card-collapse .card .card-header a[data-toggle="collapse"] i {
    color: $warning-color;
  }
}
[data-color="dark"] {
  .card-collapse .card .card-header a[data-toggle="collapse"] i {
    color: $dark-color;
  }
}
