@import "now-ui-dashboard/variables";


.theme-dark {
  & .form-control[readonly], & .form-control[disabled]  {
    background-color: #222222;
  }

  & .modal-content {
    background-color: #1c1c1c;
    color: #d2d2d2;
    border: 2px dashed;
  }
  & .modal .close {
    background-color: rgba(210, 210, 210, 0.26) !important;
    color: #fff !important;
    border-left: 2px dashed #ffffff;
    border-bottom: 2px dashed #ffffff;
  }
  & input[type="time"]::-webkit-calendar-picker-indicator{
    filter: invert(1);
    color: #ffffff;
  }

  & .blur-backplane {
    background-color: rgba(98, 98, 98, 0.45);

  }
  & .bg-gradient {
    background: linear-gradient(90deg, rgb(68, 49, 43) 0%, rgb(175, 70, 36) 100%) !important;
  }

  & .card:not(.bg-blur):not(.bg-transparent):not(.card-plain) {
    background: linear-gradient(180deg, rgb(37, 37, 37) 0%, rgb(42, 42, 42) 100%);
    /* background-color: #2a2a2a; */
    color: #ffffff;
  }
  & .card {
    & .card:not(.bg-blur):not(.bg-transparent):not(.card-plain) {
      background: linear-gradient(180deg, rgb(37, 37, 37) 0%, rgb(35, 35, 35) 100%);
      /* background-color: #2a2a2a; */
      color: #ffffff;
    }
  }
  & .table-striped tbody tr:nth-of-type(odd) {
    background-color: transparent;
  }
  & .table td {
    border-top: 1px solid #7a7a7e;
  }
  & .card-collapse .card .card-header:after {
    background-color: #7a7a7e;
  }

  & .table {
    color: #ffffff;
  }

  & .info .info-title {
    color: #ffffff;
  }

  & .main-panel {
    background: linear-gradient(90deg, #1f1f1f 0%, #1f1f1f 100%);
    color: #ffffff;
  }

  & .dropdown-menu {
    background: #2c2c2c;
    color: #ffffff;
  }

  & .form-control {
    color: #ffffff;
  }

  & option {
    background-color: #2a2a2a;
    color: #ffffff;
  }

  & .form-control:focus {
    background-color: transparent;
    color: #FFFFFF;
  }

  & .table-hover tbody tr:hover {
    color: #FFFFFF;
  }

  & .card-collapse .card .card-header a[data-toggle="collapse"] {
    color: #FFFFFF;
  }

  & .bootstrap-switch.bootstrap-switch-off .bootstrap-switch-label {
    background-color: #ffffff
  }

  & .fixed-plugin .adjustments-line .togglebutton .label-switch {
    color: #FFFFFF;
  }

  & .fixed-plugin .dropdown .dropdown-menu:after {
    border-left: 0.4em solid #2a2a2a;

  }

  & .fixed-plugin .badge {
    border: 3px solid #fff0;
  }

  & .fixed-plugin .badge.active, .fixed-plugin .badge:hover {
    border-color: #ffffff;
  }

  & input[type="time"]::-webkit-calendar-picker-indicator {
    color: #ffffff;
    filter: invert(100%);
  }

  & .form-check-radio input[type="radio"]:checked + .form-check-sign::after {
    background-color: #ffffff;
    border-color: #ffffff;
  }
  & .ps .ps__rail-x:hover, & .ps .ps__rail-y:hover, & .ps .ps__rail-x:focus, & .ps .ps__rail-y:focus, & .ps .ps__rail-x.ps--clicking, & .ps .ps__rail-y.ps--clicking {
    background-color: #363636;
    opacity: 0.9;
  }
  [data-color="dark"] {
    .card-collapse .card .card-header a[data-toggle="collapse"] i {
      color: $white-color;
    }
  }

}
.bg-dark[disabled] {
  background-color: #222222 !important;

}
:root {
  & .theme-dark {
    color-scheme: dark;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition-property: color, background-color, border-color, box-shadow;
    transition-duration: 0.15s, 0.15s, 0.15s, 0.15s;
    transition-timing-function: ease-in-out, ease-in-out, ease-in-out, ease-in-out;
    transition-delay: 0s, 0s, 0s, 0s;
  }
  & .theme-light {
    color-scheme: light;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition-property: color, background-color, border-color, box-shadow;
    transition-duration: 0.15s, 0.15s, 0.15s, 0.15s;
    transition-timing-function: ease-in-out, ease-in-out, ease-in-out, ease-in-out;
    transition-delay: 0s, 0s, 0s, 0s;
  }
}